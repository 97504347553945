/* eslint-disable max-len */
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EditList from 'src/components/AccreditationEditList/AccreditationEditList';
import EmptyGridContent from 'src/components/EmptyGridContent/EmptyGridContent';
import TabContainer from 'src/components/TabContainer/TabContainer';
import {
  selectAccreditationEditItems,
  selectAccreditationLoading,
} from 'src/redux/slices/accreditationSlice';
import { fetchAccreditationEditItemsForCategory } from 'src/redux/thunks/accreditationThunks';
import { abortPromiseOnUnmount } from 'src/services/base.service';
import {
  AccreditationEditHeader,
  AccreditationEditListModel,
} from 'src/types/AccreditationEditList';
import { IconType } from 'src/types/propTypes/EmptyGridPropTypes';
import NavigationPage from '../../components/NavigationPage/NavigationPage';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { appRoutePaths } from '../../services/route.service';
import './AccreditationEdits.css';

const AccreditationEdits = (): ReactElement => {
  const { category } = useParams();
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState<string>('');
  const isLoading = useAppSelector(selectAccreditationLoading);
  const edits: AccreditationEditListModel = useAppSelector(
    selectAccreditationEditItems
  );

  useEffect(() => {
    if (category) {
      const promise = dispatch(
        fetchAccreditationEditItemsForCategory(category)
      );
      return () => {
        abortPromiseOnUnmount(promise);
      };
    }
  }, [dispatch, category]);

  useEffect(() => {
    switch (category) {
      case 'school':
        setTitle('School Edits');
        break;
      case 'lea':
        setTitle('LEA Edits');
        break;
      case 'personnel':
        setTitle('Personnel Edits');
        break;
    }
  }, [category]);

  const isEmptyList = Object.values(edits).flat().length === 0;

  const columes = Object.keys(edits).filter((key) => edits[key].length !== 0);

  const columnHeaders = (tabName: string): AccreditationEditHeader[] => {
    const keys = Object.keys(edits[tabName][0]);
    return keys.map((eachKey) => ({ key: eachKey, displayName: eachKey }));
  };

  const buildTabs = (): ReactNode[] => {
    return columes.map((tab, index) => {
      return (
        <div
          key={`edit-${index}`}
          data-testid={`edit-${index}-tab-container`}
          className="accreditation-edit-container"
        >
          <EditList headerColumns={columnHeaders(tab)} dataRows={edits[tab]} />
        </div>
      );
    });
  };

  return (
    <NavigationPage
      heading={title}
      pageClass="data-edits--detail"
      isLoading={isLoading}
      loadingDataId="data-edits-detail-loader"
      loadingText="Getting your details"
      backBarLocation={appRoutePaths.Accreditation}
    >
      {!isEmptyList && (
        <TabContainer tabNames={columes}>{buildTabs()}</TabContainer>
      )}

      {isEmptyList && (
        <EmptyGridContent iconType={IconType.FILE}>
          <p>There are currently no edits</p>
        </EmptyGridContent>
      )}
    </NavigationPage>
  );
};

export default AccreditationEdits;
