import { ApplicationRole } from 'src/types/ApplicationRole';
import {
  getCurrentUserAccess,
  setSessionLoginRoute,
} from '../services/base.service';
import { CountyAnsiCodeSelectOptions } from '../types/CountyAnsiCodeSelectOptions';
import { CurrentUserViewModel } from '../types/CurrentUserViewModel';
import { DistrictModel } from '../types/DistrictModel';
import { FeatureAccessPermissions } from '../types/FeatureAccessPermissions';
import { FormDropdownChangeEventConfig } from '../types/propTypes/FormDropdownPropTypes';
import { SchoolSelectOption } from '../types/SchoolSelectOption';
import { UserStatus } from '../types/UserStatus';
import { UserType } from '../types/UserType';
import { formatRoutePath } from './helperUtilities';
import { isValidAfterLoginRoute } from './routeUtilities';
import { formatDistrictForDisplay } from './textUtilities';

export const isNewUser = (): boolean => {
  const details = getCurrentUserAccess();
  const hasFeatureAccessPermissions =
    details && details.featureAccessPermissions.length > 0;
  return !hasFeatureAccessPermissions;
};

export const hasRoleExcludingAdmin = (): boolean => {
  let hasRole = false;
  const details = getCurrentUserAccess();

  if (details?.featureAccessPermissions) {
    const featureAccessPermissionsMinusAdmin =
      details?.featureAccessPermissions.filter(
        (x) => x !== FeatureAccessPermissions.USER_MANAGEMENT_ACCESS
      );
    hasRole = featureAccessPermissionsMinusAdmin.length > 0;
  }
  return hasRole;
};

export const hasSpecifiedFeatureAccess = (
  desiredValue: FeatureAccessPermissions
): boolean => {
  const details = getCurrentUserAccess();
  return details?.featureAccessPermissions?.includes(desiredValue) ?? false;
};

export const isStateUser = (userType: UserType): boolean =>
  userType === UserType.State;

export const isCurrentUser = (userId: string): boolean => {
  const details = getCurrentUserAccess();
  if (details && details.userId === userId) {
    return true;
  }
  return false;
};

export const isActive = (userStatus: UserStatus): boolean => {
  if (userStatus === UserStatus.Active) {
    return true;
  }
  return false;
};

export const canChangeViewingDistrict = (
  user?: CurrentUserViewModel
): boolean => {
  const userToReview = user ? user : getCurrentUserAccess();
  return (
    (userToReview &&
      userToReview.featureAccessPermissions.includes(
        FeatureAccessPermissions.CHANGE_DISTRICT_ACCESS
      )) ||
    false
  );
};

export const isDistrictNonAdmin = (): boolean => {
  const details = getCurrentUserAccess();
  return !details?.isAdmin && details?.userType === UserType.District;
};

export const isDistrictAdmin = (): boolean => {
  const details = getCurrentUserAccess();
  return details?.isAdmin === true && details?.userType === UserType.District;
};

export const isStateAdmin = (): boolean => {
  const details = getCurrentUserAccess();
  return details?.isAdmin === true && details?.userType === UserType.State;
};

export const isStateNonAdmin = (): boolean => {
  const details = getCurrentUserAccess();
  return !details?.isAdmin && details?.userType === UserType.State;
};

export const isAdmin = (): boolean => {
  const details = getCurrentUserAccess();
  return details?.isAdmin || false;
};

export const isSAO = (): boolean => {
  const details = getCurrentUserAccess();
  return (
    details?.userType === UserType.State &&
    details?.applicationRole?.toLowerCase() ==
      ApplicationRole.SchoolAttendanceOfficer.toLowerCase()
  );
};

export const isSPO = (): boolean => {
  const details = getCurrentUserAccess();
  return (
    details?.userType === UserType.State &&
    details?.applicationRole?.toLowerCase() ==
      ApplicationRole.StateProgramOffice.toLowerCase()
  );
};

export const getRoleDisplayText = (dataDomain: string): string => {
  switch (dataDomain.toLocaleLowerCase()) {
    case 'ChildNutrition'.toLocaleLowerCase():
      return 'Child Nutrition Data';
    case 'FederalPrograms'.toLocaleLowerCase():
      return 'Federal Programs Data';
    case 'SpecialEducation'.toLocaleLowerCase():
      return 'Special Education Data';
    case 'CourseSection'.toLocaleLowerCase():
      return 'Course Section Data';
    case 'Personnel.Salary'.toLocaleLowerCase():
      return 'Salary';
    case 'Student.MsisIdRequestAccess'.toLocaleLowerCase():
      return 'MSIS ID Request';
    case 'Student.StudentOwnership'.toLocaleLowerCase():
      return 'Student Ownership';
    case 'Finance.CanCertifyDistrict'.toLocaleLowerCase():
      return 'Can Certify District';
    default:
      return `${dataDomain} Data`;
  }
};

export const storeCurrentRouteForRelogin = (route: string): void => {
  if (isValidAfterLoginRoute(route)) {
    setSessionLoginRoute(formatRoutePath(route));
  }
};

export const getSchoolNameFromOptions = (
  schoolOptions: SchoolSelectOption[],
  config: FormDropdownChangeEventConfig
): string | undefined => {
  const schoolModels = schoolOptions.filter((x) => x.value === config.value);
  if (schoolModels.length === 1) {
    return schoolModels[0].rawSchoolName;
  }
  return undefined;
};

export const getDistrictDisplay = (districts?: DistrictModel[]): string => {
  if (!districts) {
    return '';
  }

  const districtsDisplay = districts.map((district) =>
    formatDistrictForDisplay(district)
  );
  return districtsDisplay.join(', ');
};

export const getCountyAnsiCodeFromOptions = (
  countyAnsiCodeOptions: CountyAnsiCodeSelectOptions[],
  config: FormDropdownChangeEventConfig
): string | undefined => {
  const countyAnsiCodeModels = countyAnsiCodeOptions.filter(
    (eachCounty) => eachCounty.countyName === config.value
  );
  if (countyAnsiCodeModels.length === 1) {
    return countyAnsiCodeModels[0].countyAnsiCode;
  }
  return undefined;
};
