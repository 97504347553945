import { ReactElement } from 'react';
import { InfoPanelPropTypes } from '../../types/propTypes/InfoPanelPropTypes';
import './InfoPanel.css';

const InfoPanel = ({
  message,
  containerClass,
  classForParentPanel,
  panelForIcon,
}: InfoPanelPropTypes): ReactElement => {
  const classForContainer = containerClass ? containerClass : '';
  const parentPanelClass = classForParentPanel
    ? classForParentPanel
    : 'info--panel--parent';
  const panelIcon = panelForIcon ? panelForIcon : 'info--panel--icon';
  return (
    <div className={classForContainer} role="info-panel">
      <div className={parentPanelClass}>
        <div className={panelIcon}></div>
        <div className="info--panel--message">{message}</div>
      </div>
    </div>
  );
};

export default InfoPanel;
